var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.monitors
        ? _vm._l(_vm.monitors, function (monitor, index) {
            return _c("div", { key: index, staticClass: "item-wrapper" }, [
              _c("div", { staticClass: "item monitor-row" }, [
                _c("div", { staticClass: "title-title" }, [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(monitor.name)),
                  ]),
                ]),
                _c("div", { staticClass: "monitors-container" }, [
                  _c("div", { staticClass: "status-container" }, [
                    _c(
                      "span",
                      {
                        staticClass: "status-pill",
                        class: [monitor.statusClass],
                      },
                      [_vm._v(_vm._s(monitor.status))]
                    ),
                  ]),
                  _c("div", { staticClass: "status-container" }, [
                    _c("span", { staticClass: "response-time" }, [
                      _vm._v(_vm._s(monitor.responseTime) + "ms"),
                    ]),
                  ]),
                ]),
              ]),
            ])
          })
        : _vm._e(),
      _vm.errorMessage
        ? [
            _c("div", { staticClass: "error-message" }, [
              _c("span", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.errorMessage)),
              ]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }